import React from 'react'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import BigCalendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment';
import ToolbarCalendar from './ToolbarCalendar/ToolbarCalendar'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.less'
import { REQUEST, GOOGLE_EVENT } from 'utils/appointmentUtils';
import HeaderDay from './HeaderDay/HeaderDay';
import EventDetailsWrapper from './EventDetailsWrapper/EventDetailsWrapper'
import { MICROSOFT_EVENT } from '../utils/appointmentUtils';

const DragAndDropCalendar = withDragAndDrop(BigCalendar)

class Dnd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: props.events,
    }

    this.onMove = this.props.onMove.bind(this)
  }

  render() {

    let minHours = 7;

    let maxHours = 21;
    const {availableTags,events} = this.props;
    for(let i = 0; i < events.length; i++ ) {
      if(events[i].start.getHours()<minHours) {
        minHours = events[i].start.getHours();
      }
      if(events[i].end.getHours()>maxHours) {
        maxHours = events[i].end.getHours();
      }
    }
    const timeMin = `${minHours}:00`;
    const timeMax = `${maxHours}:00`;
    const currentDate = moment().format('D MMM YYYY');
    return (
      <DragAndDropCalendar
        components={{
          toolbar: (data) => (
            <ToolbarCalendar
              {...data}
              selectedTags={this.props.selectedTags}
              onChange={(tags) => this.props.onTagChange(tags, 'tags')}
              availableTags={availableTags}
            />
          ),
          day: {
            header: HeaderDay
          },
          work_week: {
            header: HeaderDay
          },
          week: {
            header: HeaderDay
          },
          month: {
            event: EventDetailsWrapper
          }
        }}
        popup = {true}
        events={events}
        onEventDrop={this.onMove}
        resizable={true}
        views={this.props.views}
        onEventResize={(event) => console.log(event)}
        defaultView="work_week"
        defaultDate={new Date()}
        min={new Date(`${currentDate}, ${timeMin}`)}
        max={new Date(`${currentDate}, ${timeMax}`)}
        step={30}
        formats={this.props.formats}
        date={this.props.date}
        view={this.props.view}
        selectable={true}
        onNavigate={(date, view) => this.props.onNavigate(date,view)}
        onView={(view) => this.props.onView(view)}
        onSelectSlot={(slotInfo) => {this.props.onSelectSlot(slotInfo)}}
        onSelectEvent={(eventInfo) => this.props.onSelectEvent(eventInfo)}
        eventPropGetter={(event, start) => {

          
          if(event.type===REQUEST) {

            return {
              className:'calendar_event_cell_request',
            }
          }
          if(event.type===GOOGLE_EVENT) {

            return {
              className:'calendar_event_cell_google',
            }
          }
          if(event.type===MICROSOFT_EVENT) {

            return {
              className:'calendar_event_cell_microsoft',
            }
          }
          if(start.getTime() < Date.now()){
            
            return {
              className:'calendar_event_cell_date_expired',
            }
          
        } else {
          if(event.confirmed) {
            return {
              className:'calendar_event_cell_date_confirmed',
            }
          }
          return {
            className:'calendar_event_cell_date',
          }
        }}}
      />
    )
  }
}





const DnDCalendar = DragDropContext(HTML5Backend)(Dnd)
export default DnDCalendar