import React, { Component } from 'react'
import { Modal, Button, Form, TextArea, Grid, Divider, Checkbox, Segment, Dimmer, Loader, Input} from 'semantic-ui-react'
import moment from 'moment'
import 'react-widgets/dist/css/react-widgets.css';
import {TextFormField} from 'widgets/FormWidgets'
import { ClientsSelectionContainer } from 'containers/ClientsSelectionContainer/ClientsSelectionContainer';
import TagsSelection from 'widgets/TagsSelection';
import ClickableDateTimePicker from 'widgets/ClickableDateTimePicker';
import modalInlineStyle from 'utils/modalCSSFix';
import { CommunicationSetup } from 'widgets/CommunicationSetup';
import { sortByOffsetTypes } from 'utils/offsetTypeUtils';
import SyncedEventAttendeeDisplay from '../../widgets/SyncedEventAttendeeDisplay/SyncedEventAttendeeDisplay';
import { ErrorBoundary } from '../../widgets/ErrorBoundary/ErrorBoundary';

export class AppointmentDetail extends Component {
    constructor(props) {
        super(props);
        let reminderKeys = ['first_reminder','second_reminder','third_reminder'];
        this.state = {
           selectedAppointment:props.selectedAppointment,
           endPicker_open:'date'
        }
            let sortedReminders = sortByOffsetTypes(props.reminderOffsets, props.selectedAppointment.appt_reminders);
            let sortedPreferences = sortByOffsetTypes(props.reminderOffsets, props.communicationPreferences);
            reminderKeys.forEach((reminderKey, index) => {
                let reminder;
                if (props.selectedAppointment.uuid) {
                    reminder = sortedReminders[index]
                } else {
                    if(sortedPreferences[index]) {
                        let {offset_type, communication_message_template, communication_type, status} = sortedPreferences[index]
                        reminder = {communication_type, communication_message_template, status, offset_type};
                    } 
                }
                if(!reminder) {
                    reminder = this.createNewReminder(index);
                }
                this.state[reminderKey] = reminder;
            })
        

    }

    createNewReminder(index) {
            let offset = this.props.reminderOffsets.length>index?this.props.reminderOffsets[index]:this.props.reminderOffsets[this.props.reminderOffsets.length-1];
            let offsetDate = moment(this.props.selectedAppointment.start_timestamp).add(offset.offset, 'ms').endOf('day');
            return {
                communication_type:this.props.communicationTypes[0].name,
                communication_message_template:this.props.communicationMessageTemplates[0].uuid,
                status:moment().isAfter(offsetDate)?'Inactive':'Active',
                offset_type:offset.name
                };
    }

    update(value, key) {
        let updated = {...this.state.selectedAppointment};
        if( key === "end_timestamp") {
            const {start_timestamp} = updated;
            const new_end = moment(value, moment.ISO_8601);
            const start = moment(start_timestamp, moment.ISO_8601)
            if(!new_end.isAfter(start)) {
                alert('Start needs to be before End');
                return;
            }
        }
        
        if(key === "start_timestamp") {
            const {start_timestamp, end_timestamp} = updated;
            const length = moment(end_timestamp,moment.ISO_8601).toDate().getTime() - moment(start_timestamp,moment.ISO_8601).toDate().getTime();
            updated.start_timestamp = value;
            updated.end_timestamp = moment(new Date(moment(value,moment.ISO_8601).toDate().getTime()+length)).toISOString();
        } else {
            updated[key] = value;
        }

        /*if(key==='clients') {
            updated.title = concatedClientNames(value, this.props.availableClients);
        }*/
        this.setState(
            {...this.state, 
                selectedAppointment:updated
            }
        )
    }

    _endTimePicker_openClick(event) {
        if (event.target.tagName === 'INPUT') {
          this.setState({...this.state, endTimePicker_open: 'calendar'});
        }
      }

      _endTimePicker_handleToggle(view) {
        this.setState({ ...this.state, endTimePicker_open: view })
      }

      availableReminderOffsets(offsetType) {
        return this.props.reminderOffsets;
        //this.props.reminderOffsets.fo(offset => moment(offset.offset_date).isBefore(moment(this.props.selectedAppointment.start_timestamp)))
       /*  return this.props.reminderOffsets.filter(offset => {
            return ((offsetType) && offsetType.offset_type === offset.name) || moment(offset.offset_date).isBefore(moment(this.props.selectedAppointment.start_timestamp))
        } 
        )*/
      }

      reminderDisabled(reminder) {
        if (!reminder) {
            return false;
        }
        let reminderOffset = this.props.reminderOffsets.filter(offset => {
            return offset.name === reminder.offset_type
        }
        )[0];  
        if(!reminderOffset) {
            return false;
        }
        return reminder.status === 'Processed' //|| moment(reminderOffset.offset_date).is(moment(this.props.selectedAppointment.start_timestamp)))
      }

    saveChanges(){
        
        this.props.saveSelectedAppointment({...this.state.selectedAppointment,
            appt_reminders:[this.state.first_reminder,
                this.state.second_reminder,
                this.state.third_reminder]
        })
    }

    templatePreviewData() {
        if(this.state.selectedAppointment) {
        return {
            date:this.state.selectedAppointment.start_timestamp,
            receiver:this.state.selectedAppointment.clients[0],
            sender:this.props.professional
            }
        } else {
            return {}
        }
    }


   renderLabel(option, index, labelProps ){
       const confirmed = this.state.selectedAppointment.confirmed_clients.indexOf(option.value)!==-1;
        return {color:confirmed?'green':null,content:option.text,icon:confirmed?'checkmark':null}
    };

    reminderCommunicationTypes() {
        let reminderOccasion = this.props.occasions
        .filter(occasion => occasion.name === 'DATE_REMINDER')[0];
       return this.props.communicationTypes.filter(communicationType => {
            
            return reminderOccasion.communication_types.includes(communicationType.name)
       })
    }

    templatesForOccasion() {
       return this.props.communicationMessageTemplates
       .filter(template => template.occasion === 'DATE_REMINDER')
    }

    render () {
        console.log('props', this.props);
        let confirmation = null;//this.state.selectedAppointment.confirmations[0];
        return (
            <Modal 
                dimmer={this.dimmer}
                closeOnDimmerClick={false}
                open={this.state.selectedAppointment!=null}
                style={modalInlineStyle}
                onClose={() => this.props.close()}
                closeIcon
            >
                  <Modal.Header>{(this.state.selectedAppointment.uuid?'Edit':'Create')+' Appointment'}</Modal.Header>
                  <Dimmer active={this.props.loading}>
                    <Loader />
                </Dimmer>
                  <Modal.Content>
                    {   confirmation&&(
                        <Segment color='green' piled>{`Appointment was confirmed ${moment(confirmation.date).fromNow()} through ${confirmation.communication_type}`}</Segment>
                    )}
                    <Modal.Description>
                    <Form size='tiny'>
                    <Grid>
        <Grid.Column width={6} textAlign="left"> 
        <Form.Group widths='equal'>
        <TextFormField 
            valueKey='title'
            control={Input} 
            label='Title&#42;' 
            object={this.state.selectedAppointment}
            update={(value, key) => this.update(value, key)}            
        />
</Form.Group>

    <Form.Group widths='equal'>
    <Form.Field>
    <label>Start&#42;</label>
    <ClickableDateTimePicker 
    value={moment(this.state.selectedAppointment.start_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'start_timestamp')}
    />
    </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>
    <Form.Field>
    <label>End&#42;</label>
    <ClickableDateTimePicker
    value={moment(this.state.selectedAppointment.end_timestamp, moment.ISO_8601).toDate()}
    onChange={(value) => this.update(moment(value).toISOString(), 'end_timestamp')}
    />
    </Form.Field>
    </Form.Group>
    <Form.Group widths='equal'>
    <Form.Field>
        <label>Length</label>
        {length(this.state.selectedAppointment)}
        </Form.Field> 
        </Form.Group>
</Grid.Column>
<Grid.Column width={10} textAlign="left">
<Form.Field>
    <label>Client(s)&#42;</label>
<ClientsSelectionContainer 
    availableClients={this.props.availableClients}
    selectedClients={this.state.selectedAppointment.clients} 
    onChange={(clients) => this.update(clients, 'clients')} 
    renderLabel={(option, index, labelProps ) => this.renderLabel(option, index, labelProps)}
    />
</Form.Field>

<Form.Field>
      <Checkbox 
        label='Notify Client(s)' 
        checked={this.state.selectedAppointment.notify_clients}
        toggle
        onChange={(event, {checked}) => this.update(checked,'notify_clients')}
        />
    </Form.Field>
    <SyncedEventAttendeeDisplay syncedEvent={this.state.selectedAppointment.google_events[0]||this.state.selectedAppointment.microsoft_events[0]} />
<Form.Field>
    <label>Tag(s)</label>
<ErrorBoundary><TagsSelection availableTags={this.props.availableTags} selectedTags={this.state.selectedAppointment.tags} onChange={(tags) => this.update(tags, 'tags')}/></ErrorBoundary>
</Form.Field>
<TextFormField 
                    valueKey='notes'
                    control={TextArea} 
                    label='Notes' 
                    object={this.state.selectedAppointment}
                    update={(value, key) => this.update(value, key)}
                />
</Grid.Column>
</Grid>
<Divider horizontal>Reminders</Divider>
<table width="100%">
    <thead>
      <tr>
        <th>Active</th>
        <th>Lead Time</th>
        <th>Communication Type</th>
        <th>Message Template</th>
        <th>Preview</th>
      </tr>
    </thead>

    <tbody>
        {
            ['first_reminder', 'second_reminder', 'third_reminder'].map((reminderKey, index) => {
                let displayedReminder = this.state[reminderKey]
                return (
                    <CommunicationSetup 
                        disabled={(reminder) => this.reminderDisabled(reminder)} 
                        showPreview={this.state.selectedAppointment.clients.length>0} 
                        index={index} 
                        reminderOffsets={this.availableReminderOffsets(displayedReminder)} 
                        communicationTypes={this.reminderCommunicationTypes()} 
                        communicationMessageTemplates={this.templatesForOccasion()} 
                        reminder={displayedReminder} 
                        onChange={(reminder) => {let newState = {...this.state}; 
                        newState[reminderKey] = reminder; 
                        this.setState(newState)}} 
                        key={`${reminderKey}_${index}`}
                        date={this.state.selectedAppointment.start_timestamp}
                        receiver={this.state.selectedAppointment.clients[0]}
                        sender={this.props.professional}
                        //previewURL = {BASE_URL+'/prw/'+displayedReminder.communication_message_template+'/emailHTMLMessageTemplate?pl='+encodeURIComponent(JSON.stringify(this.templatePreviewData()))}
                        />  
                       
                )
            })
        }
</tbody>
  </table>
  </Form>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color='grey' onClick={() => this.props.close()}>
                      Cancel
                    </Button>
                    {
                    this.state.selectedAppointment.uuid&&(
                            <Button content="Delete" color='red' icon="trash" labelPosition='right' onClick={() => {if(window.confirm('Do you really want to delete this appointment?'))this.props.deleteAppointment(this.state.selectedAppointment)}} />
                        )
                    } 
                    
                    <Button positive icon='checkmark' labelPosition='right' content="Save" onClick={() => this.saveChanges()} />
                  </Modal.Actions>
                </Modal>
        )
    }
}

function length(appt) {

    const length = moment.duration(moment(appt.end_timestamp, moment.ISO_8601).diff(moment(appt.start_timestamp, moment.ISO_8601)));
    if(length.days()>=1) {
        const daysString = length.days()>1?'days':'day';
       return `${length.days()} ${daysString}`;
    }
    if(length.hours()>=1) {
        const hoursString = length.hours()>1?'hours':'hour';
        const minutesString = length.minutes()>1?':'+length.minutes():'';
       return `${length.hours()}${minutesString} ${hoursString}`;
    }
    return `${length.minutes()} minutes`;
}
