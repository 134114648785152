import React from 'react'
import { Menu, Icon, Image, Dropdown, Label } from 'semantic-ui-react'
import logo from 'assets/logoW.png'

import './NavigationTabs.css'
import { IS_BETA } from '../../App'

export const NavigationTabs = ({ activeItem, onLogOut, onClick, onRestartTour }) => (
  <div>            
    <Dropdown icon="bars" id="mobile-nav" trigger={<Image src={logo} width="100px" />} className='link item'>
      <Dropdown.Menu>
        <Dropdown.Item
          className="navigation-calendar-button"
          name='Calendar'
          active={activeItem === 'calendar'}
          onClick={() => onClick('calendar')}
        >
          <Icon name='calendar' />
          Calendar
        </Dropdown.Item>
        <Dropdown.Item
          className="navigation-clients-button"
          name='Clients'
          active={activeItem === 'clients'}
          onClick={() => onClick('clients')}
        >
          <Icon name='users' />
          Clients
        </Dropdown.Item>
        <Dropdown.Item
          className="navigation-tags-button"
          name='Tags'
          active={activeItem === 'tags'}
          onClick={() => onClick('tags')}
        >
          <Icon name='tags' />
          Tags
        </Dropdown.Item>
        <Dropdown.Item
          className="navigation-smarttags-button"
          name='SmartTags'
          active={activeItem === 'smarttags'}
          onClick={() => onClick('smarttags')}
        >
          <Icon name='bolt' />
          SmartTags
        </Dropdown.Item>
        <Dropdown.Item
          className="navigation-settings-button"
          name='Settings'
          active={activeItem === 'settings'}
          onClick={() => onClick('settings')}
        >
          <Icon name='setting' />
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => onRestartTour ? onRestartTour() : null } >
          <Icon name='flag' />
          Help
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onLogOut ? onLogOut() : null } >
          <Icon name='sign-out' />
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Menu stackable secondary id="main-nav">
      <Menu.Item
      className="hero-img"
      onClick={() => onClick('calendar')}
      >
        <Image src={logo} width="100px" />
      </Menu.Item>
      <Menu.Item
      className="navigation-calendar-button"
      name='Calendar'
      active={activeItem === 'calendar'}
      onClick={() => onClick('calendar')}
      >
        <Icon name='calendar' />
        Calendar
      </Menu.Item>
      <Menu.Item
      className="navigation-clients-button"
      name='Clients'
      active={activeItem === 'clients'}
      onClick={() => onClick('clients')}
      >
        <Icon name='users' />
        Clients
      </Menu.Item>
      <Menu.Item
      className="navigation-tags-button"
      name='Tags'
      active={activeItem === 'tags'}
      onClick={() => onClick('tags')}
      >
        <Icon name='tags' />
        Tags
      </Menu.Item>
      <Menu.Item
      className="navigation-smarttags-button"
      name='SmartTags'
      active={activeItem === 'smarttags'}
      onClick={() => onClick('smarttags')}
      >
        <Icon name='bolt' />
        SmartTags
      </Menu.Item>
      <Menu.Item
      className="navigation-settings-button"
      name='Settings'
      active={activeItem === 'settings'}
      onClick={() => onClick('settings')}
      >
        <Icon name='setting' />
      Settings
      </Menu.Item>
      <Menu.Item >
  {IS_BETA&&<Label color='red' icon="key" content="Beta" />}
</Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item onClick={() => onRestartTour ? onRestartTour() : null } >
          <Icon name='flag' />
          Help
        </Menu.Item>
        <Menu.Item onClick={() => onLogOut ? onLogOut() : null } >
          <Icon name='sign-out' />
          Log Out
        </Menu.Item>
      </Menu.Menu>
    </Menu>
    
  </div>
)