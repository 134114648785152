import React from 'react'
import { Modal, Button, Header, Icon, Form, Segment, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { EMAIL } from 'constants/CommunicationTypes';
import { sendInstantMessage } from 'actions/InstantMessageActions';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    convertToRaw,
  ContentState,
  bold,
  italic,
  underline,
  superscript,
  subscript,
  fontSize,
  unordered,
  ordered,
  indent,
  outdent,
  left,
  center,
  right,
  color,
  link,
  unlink,
  image,
  eraser,
  undo,
  redo,
  justify,
  strikethrough,
  monospace,
  emoji
  } from 'draft-js';
  import draftToHtml from 'draftjs-to-html';
  import htmlToDraft from 'html-to-draftjs';
  import _ from 'lodash';



const mapStateToProps = (state, ownProps) => {
    return {
        sendingInProgress : state.session.sendingEmail,
        sender : state.session.professional
    }
}




const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendInstantMessage: (clients, sender, message, emailSubject) => {
            dispatch(sendInstantMessage(EMAIL, clients.map(client => client.uuid), sender, message, emailSubject))
        }
    }
}
function  message(client, htmlSignature) {
    return `<p>Hello ${_.defaultTo(client.given_name,'')}</p><p>[YOUR MESSAGE HERE]</p><br />${htmlSignature}`;
}

class InstantEmailComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentEmail: false,
            email_subject: '',
            message: _.defaultTo(message(props.client,props.html_signature),''),
            html_signature: props.html_signature,
        }
    }



    static message(client, htmlSignature) {
        return `Hello ${_.defaultTo(client.given_name,'')}<br /><br />[YOUR MESSAGE HERE]<br /><br />${htmlSignature}`;
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {...state};
        if (state.html_signature !== props.html_signature) {
            newState = {...newState,html_signature:props.html_signature,message: message(props.client,props.html_signature)}
        }
        if (state.professional !== props.professional) {
            newState = {...newState,
                professional:props.professional, 
                email_subject:`Message from ${_.join([props.professional.given_name,props.professional.family_name], ' ')}, ${props.office.name}`}

        }
        return newState;
    }

    initialContentState() {
        const contentBlock = htmlToDraft(_.defaultTo(message(this.props.client,this.props.html_signature),''));
        //this.setState({...this.state,message:this.message()});
        if (contentBlock) {
            return convertToRaw(ContentState.createFromBlockArray(contentBlock.contentBlocks));
           
          } else {
              return convertToRaw({});
          } 
    }

    handleOpen () {
        this.setState({ modalOpen: true })
    }

    handleClose () {
        this.setState({ 
            sentEmail: false,
            modalOpen: false,
            email_subject: '',
            message: '' 
        })
    }

    handleSend() {
        this.setState({...this.state, sentEmail:true})
        this.props.sendInstantMessage([this.props.client], this.props.sender, this.state.message, this.state.email_subject);
    }

    showSpinner() {
        return this.state.sentEmail && this.props.sendingInProgress;
    }

    messageDisplay() {
        return  <Segment>
                <code>{this.state.email_subject}</code>
                <p><code>--------------------------------------</code></p>
                <Editor
                        //wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        //toolbarClassName="toolbar-class"
                        initialContentState={this.initialContentState()}
                        readOnly={true}
                        toolbarHidden={true}
                        //toolbar={editorToolbar}
                        //onChange={(contentState) => this.setState({...this.state,message:draftToHtml(contentState)})}
                    />
            </Segment>
    }
    displaySpinner() {
        if(this.showSpinner()) {
            return (
                <Modal.Content image>
                    <Icon loading name='spinner' size='massive'/>
                    <Modal.Description>
        <Header>Sending in progress...</Header>
            {this.messageDisplay()}
      </Modal.Description>
                </Modal.Content>
            )
        }
        return null;
    }

    showDone() {
        return this.state.sentEmail && !this.props.sendingInProgress;
    }

    displayDone() {
        if(this.showDone()) {
            return (
                <React.Fragment>
                <Modal.Content image>
                    <Icon name='checkmark'  size='massive' color='green' inverted/>
                    <Modal.Description>
        <Header>Message successfully sent!</Header>
                {this.messageDisplay()}
      </Modal.Description>
            
                </Modal.Content>
                                <Modal.Actions>
                                <Button onClick={(e) => {e.stopPropagation();this.handleClose()}} color='green'>
                                    <Icon name='check' />OK
                                </Button>
                            </Modal.Actions>
                        </React.Fragment>
            )
        }
        return null;
    }

    showForm() {
        return !this.state.sentEmail;
    }

    displayForm() {
        if(this.showForm()) {
            return (
                <React.Fragment>
                    <Modal.Content>
                        <Form>
                            <Form.Input fluid label='Subject' value={this.state.email_subject} placeholder='Your subject here...' onChange={(e, {value}) => this.setState({...this.state,email_subject:value})}/>
                            <Container>
                            <Editor
                        //wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        initialContentState={this.initialContentState()}
                        toolbar={editorToolbar}
                        onChange={(contentState) => this.setState({...this.state,message:draftToHtml(contentState)})}
                    />
                         </Container>
                        </Form>
                        </Modal.Content>
                        <Modal.Actions>
                        <Button onClick={(e) => {this.handleClose()}} color='red'>
                            <Icon name='cancel' /> Cancel
                        </Button>
                        <Button disabled={!(this.state.message&&this.state.message.length>0
                        &&this.state.email_subject&&this.state.email_subject.length>0)} onClick={(e) => {e.stopPropagation();this.handleSend()}} color='green'>
                            <Icon name='send' /> Send Email
                        </Button>
                    </Modal.Actions>
                </React.Fragment>
            )
        }
        return null
    }
    render() {
        return <Modal
        trigger={<Button onClick={(e) => {this.handleOpen();e.stopPropagation()}}>{this.props.client.email}</Button>}
        open={this.state.modalOpen}
        size='large'
    >
    <Header icon='mail' content={`Send Email to ${this.props.client.given_name} ${this.props.client.family_name}`} />
    {this.displayForm()}
    {this.displaySpinner()}
    {this.displayDone()}
</Modal>
    }
}

export const editorToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
    inline: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
      bold: { icon: bold, className: undefined },
      italic: { icon: italic, className: undefined },
      underline: { icon: underline, className: undefined },
      strikethrough: { icon: strikethrough, className: undefined },
      monospace: { icon: monospace, className: undefined },
      superscript: { icon: superscript, className: undefined },
      subscript: { icon: subscript, className: undefined },
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered', 'indent', 'outdent'],
      unordered: { icon: unordered, className: undefined },
      ordered: { icon: ordered, className: undefined },
      indent: { icon: indent, className: undefined },
      outdent: { icon: outdent, className: undefined },
    },
    textAlign: {
      inDropdown: true,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: left, className: undefined },
      center: { icon: center, className: undefined },
      right: { icon: right, className: undefined },
      justify: { icon: justify, className: undefined },
    },
    colorPicker: {
      icon: color,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
    },
    link: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: '_self',
      options: ['link', 'unlink'],
      link: { icon: link, className: undefined },
      unlink: { icon: unlink, className: undefined },
    },
      emoji: {
        icon: emoji,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        emojis: [
          '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
          '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
          '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
          '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
          '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
          '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
          '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
          '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
          '✅', '❎', '💯',
        ],
      },
    image: {
      icon: image,
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    remove: { icon: eraser, className: undefined, component: undefined },
    history: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['undo', 'redo'],
      undo: { icon: undo, className: undefined },
      redo: { icon: redo, className: undefined },
    },
  };

const InstantEmail = connect(mapStateToProps, mapDispatchToProps)(InstantEmailComponent);
export {InstantEmail};