import { SHOW_MIC, SHOW_MIC_FULLFILLED } from 'actions/MICActions';

export const MICReducer = (state = {
    showMIC: JSON.parse(window.sessionStorage.getItem('showMIC')),
    account_token: window.sessionStorage.getItem('account_token')
}, action) => {
    switch (action.type) {
        case SHOW_MIC:
            return state;
        case SHOW_MIC_FULLFILLED:
            window.sessionStorage.setItem('showMIC', action.payload.showMIC)
            window.sessionStorage.setItem('account_token', action.payload.account_token)
            return {...state, ...action.payload};
        default:
            return state;
    }
}