import React, { Component } from 'react'
import { Label, Grid, Input, Button, Icon } from 'semantic-ui-react'
import {ClientDetail} from 'components/ClientDetail/ClientDetail'
import {clientHasSearchString, clientHasAnyTag} from 'utils/clientUtils'
import {tagWithId} from 'utils/tagUtils'
import TagsSelection from 'widgets/TagsSelection'
import {DataTable} from 'widgets/DataTable'
import PropTypes from 'prop-types';
import Wrapper from 'widgets/Wrapper';
import ComponentWrapper from 'widgets/ComponentWrapper/ComponentWrapper'
import moment from 'moment'
import { AppointmentDetail } from 'components/AppointmentDetail/AppointmentDetail';
import { InstantEmail } from 'widgets/InstantEmail';
import { PaginationFooter } from 'widgets/PaginationFooter/PaginationFooter';
import _ from 'lodash'


export class Clients extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchString : '',
            tags : [],
            activePage: 1,
        };
    }


    handleSaveChanges(updatedClient) {
        this.props.handleSaveChanges(updatedClient)
    }

    handleDeleteClient(deletedClient) {
        this.props.handleDeleteClient(deletedClient)
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ 
            ...this.state,
            searchString : value
        })
    }
    handleSearch = _.debounce(() => {
        this._handleFetchClients({ page: 1 })
    }, 500)
    
    handleTagsChange(tags) {
        this.setState({ 
            ...this.state,
            tags
        })
        this._handleFetchClients({
            page: 1,
            tags
        })
    }

    handlePagination(activePage) {
        this._handleFetchClients({ page: activePage })
    }

    handlePageSize(page_size) {
        this._handleFetchClients({ page: 1, page_size })
    }

    _handleFetchClients({
        professional=this.props.professional,
        page=this.props.clients_pagination_info.page,
        page_size=this.props.clients_pagination_info.page_size,
        searchString=this.state.searchString,
        tags=this.state.tags
    }) {
        this.props.handleFetchClients(professional, page, page_size, searchString, tags);
    }

    componentDidMount() {
        this.props.handleFetchSettings();
        const { professional, clients_pagination_info: { page, page_size }} = this.props;
        const { searchString, tags} = this.state
        this.props.handleFetchClients(professional, page, page_size, searchString, tags);
        this.props.handleFetchTags(professional);
    }

    render () {
        const stringOrdering = (a, b, key) => {
            if(!a&&b) {
                return -1;
            }
            if(a&&!b) {
                return 1;
            }
            if(!a&&!b) {
                return 0;
            }
            if(key) {
                let compA = a[key]?a[key].toLowerCase():''
                let compB = b[key]?b[key].toLowerCase():''
                return compA.localeCompare(compB) 
            }
            return a.toLowerCase().localeCompare(b.toLowerCase())
        };
        return (
            <ComponentWrapper>
                <Wrapper icon="users" sloading={this.props.loading}>
                    <Grid>
                        <Grid.Column width={3}>
                            <Input 
                                icon='search' 
                                placeholder='Filter...' 
                                onChange={this.handleSearchChange}
                                onInput={this.handleSearch}
                                value={this.state.searchString}
                                fluid
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Button circular icon labelPosition='right' onClick={() => this.props.handleAddClient({professional:this.props.professional,client_type:'Person',gender:'m',tags:[], client_phones:[], can_email:true})}>
                                Add Client  <Icon name='add user' />
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={4} floated="right">
                            <TagsSelection 
                                fluid
                                onChange={(tags) => this.handleTagsChange(tags)} 
                                selectedTags={this.state.tags}
                                availableTags={this.props.availableTags}
                            />
                        </Grid.Column>
                    </Grid>
                    <DataTable 
                    professaionalObject = {this.props.professional_object}
                        id="appointments"
                        columnDescription={[
                            {
                                key:'given_name',
                                header:'First Name',
                                sorting:(a,b) => stringOrdering(a, b, 'given_name')
                            },
                            {
                                key:'family_name',
                                header:'Last Name',
                                sorting:(a,b) => stringOrdering(a, b, 'family_name')
                            },
                            {
                                key:'company_name',
                                header:'Company',
                                sorting:(a,b) => stringOrdering(a, b, 'company_name')
                            },
                            {
                                key:'email',
                                header:'Email',
                                format:(email, client) => email?<InstantEmail client={client} office={this.props.office} professional={this.props.professional_object} html_signature={this.props.professional_object?this.props.professional_object.html_signature:''}/>:null,
                                sorting:(a,b) => stringOrdering(a, b, 'email')
                            },
                            {
                                key:'tags',
                                header:'Tags',
                                format:(tags) => <React.Fragment>{this.props.availableTags&&this.props.availableTags.length>0&&tags.map((tag) => (
                                    <Label key={tag} color={tagWithId(tag, this.props.availableTags).color}>
                                        {tagWithId(tag, this.props.availableTags).name}
                                    </Label>   
                                ))}</React.Fragment>,
                                sorting:(clientA, clientB) => {
                                    let a = clientA.tags;
                                    let b = clientB.tags;
                                    if(a.length===0 && b.length===0) {
                                        return 0;
                                    }
                                    if(a.length > 0 && b.length===0) {
                                        return 1;
                                    }
                                    if(a.length===0 && b.length > 0) {
                                        return -1;
                                    }
                                    return stringOrdering(tagWithId(a[0], this.props.availableTags).name, tagWithId(b[0], this.props.availableTags).name)
                                }
                            },
                            {
                                key:'next_date',
                                header:'Next Appointment',
                                format:(date, client) => <React.Fragment>{ client.next_date&&<Button onClick={(event) => {
                                    event.stopPropagation();
                                    this.props.handleShowDate(client.uuid,client.next_date);
                                }}>{moment(client.next_date.start_timestamp).format('MM/DD/YYYY[ at ]hh:mmA')}</Button>}</React.Fragment>,
                                sorting:(a, b) => {
                                    if (a.next_date&&!b.next_date) {
                                        return 1;
                                    }
                                    if (!a.next_date&&b.next_date) {
                                        return -1;
                                    }
                                    if (!a.next_date&&!b.next_date) {
                                        return 0;
                                    }
                                    if (moment(a.next_date.start_timestamp).isAfter(moment(b.next_date.start_timestamp))) {
                                        return 1;
                                    }
                                    if (moment(b.next_date.start_timestamp).isAfter(moment(a.next_date.start_timestamp))) {
                                        return -1;
                                    }
                                    return 0;
                                }
                            }
                        ]}
                        onSelectRow={(client) => this.props.handleSelectClient(client)}
                        initialSorting= {{
                            sorting: (a,b) => stringOrdering(a, b, 'family_name'),
                            asc:true,
                            key:'family_name'
                        }}
                        dataRows={this.props.clients.filter(client => clientHasAnyTag(client, this.state.tags) && clientHasSearchString(client, this.state.searchString))}
                        pageSize={50}
                        maxPageCount={10}
                    />
                    <PaginationFooter 
                        {...this.props.clients_pagination_info}
                        handlePageSizeChange={(e,d) => this.handlePageSize(e,d)}
                        handlePaginationChange={(e,d) => this.handlePagination(e,d)}
                    />

                </Wrapper>
                {
                    this.props.selectedClient && (
                    <ClientDetail 
                            onDeleteClient={client => this.handleDeleteClient(client)}
                            selectedClient={this.props.selectedClient} 
                            close={() => this.props.handleClearSelectedClient()} 
                            onSaveChanges={(client) => this.handleSaveChanges(client)}
                            availableTags={this.props.availableTags}
                            loading={this.props.loading}
                    />)
                }
                {
                    this.props.selectedAppointment && (
                    <AppointmentDetail 
                    communicationMessageTemplates={this.props.communicationMessageTemplates}
                    reminderOffsets={this.props.reminderOffsets}
                    communicationTypes={this.props.communicationTypes}
                    occasions={this.props.occasions}
                    availableClients={this.props.availableClients}
                    deleteAppointment={(appointment) => this.props.deleteAppointment(appointment)}
                    loading={this.props.loading}
                    selectedAppointment={this.props.selectedAppointment} 
                    close={() => this.props.onClearSelectedAppointment()} 
                    update={(value, valueKey) => console.log(value, valueKey)}
                    saveSelectedAppointment={(appointment) => this.props.onSaveAppointment(appointment,this.props.clients,this.props.professional)}
                    availableTags={this.props.availableTags}
                    professional={this.props.professional}
                    communicationPreferences={this.props.communicationPreferences}
                    />)
                }
            </ComponentWrapper>
        )
    }
}

Clients.propTypes = {
    clients:PropTypes.array,
    handleSaveChanges:PropTypes.func,
    handleDeleteClient:PropTypes.func
}
