export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FULLFILLED = "LOGIN_FULLFILLED";

export const PING = "PING";
export const PING_FULLFILLED = "PING_FULLFILLED";

export const CHANGE_USERNAME = "CHANGE_USERNAME"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_SCOPE = 'CHANGE_SCOPE'
export const SET_LOGIN_ERROR_MESSAGE = "SET_LOGIN_ERROR_MESSAGE";
export const RESTART_TOUR = 'RESTART_TOUR';
export const END_TOUR = 'END_TOUR';
export const HANDLE_ERROR = 'HANDLE_ERROR';
export const DISMISS_ERRORS = 'DISMISS_ERROR';
export const UNSAVED_CHANGES = 'UNSAVED_CHANGES';

const login = (username, password) =>  { 
    return {
        type:LOGIN,
        payload:{username,password}
    }
}

const ping = () => {
    return {
        type : PING
    }
}

const pingFullfilled = ({at}) => {
    return {
        type : PING_FULLFILLED,
        payload : {at}
    }
}

const changeUsername = (username) =>  { 
    return {
        type:CHANGE_USERNAME,
        payload: {username}
    }
}

const restartTour = () =>  { 
    return {
        type:RESTART_TOUR
    }
}

const endTour = () =>  { 
    return {
        type:END_TOUR
    }
}

const changePassword = (password) =>  { 
    return {
        type:CHANGE_PASSWORD,
        payload: {password}
    }
}

const changeScope = (scope) =>  { 
    return {
        type:CHANGE_SCOPE,
        payload: {scope}
    }
}

const loginFullfilled = ({professionals, primary_professional, uuid, at, role, offices, company, scope, last_login_date}) =>  { 
    return {
        type:LOGIN_FULLFILLED,
        payload: {
                professional: primary_professional || professionals[0],
                user_id : uuid,
                role,
                professionals:professionals,
                offices:offices,
                office:offices[0],
                company,
                scope,
                at, 
                last_login_date
            }
    }
}

const setLoginErrorMessage = (message) => {
    return {
        type:SET_LOGIN_ERROR_MESSAGE,
        payload: {message}
    }
}

const logout = () =>  { 
    return {
        type:LOGOUT
    }
}

const handleError = (error) => {
    console.log('handleError', error)
    return {
        type: HANDLE_ERROR,
        payload: {error}
    }
}

const dismissErrors = () => {
    return {
        type: DISMISS_ERRORS
    }
}

export function unsavedChanges(hasUnsavedChanges) {
    return {
        type: UNSAVED_CHANGES,
        payload: {hasUnsavedChanges}
    }
}

export {login, loginFullfilled, logout, endTour, changeUsername, changePassword, changeScope, setLoginErrorMessage, restartTour, handleError, dismissErrors, ping, pingFullfilled};