export const SHOW_MIC = 'SHOW_MIC'
export const SHOW_MIC_FULLFILLED = 'SHOW_MIC_FULLFILLED'


const showMIC = (micIsOpen) => {
    return {
        type : SHOW_MIC,
        payload : micIsOpen
    }
}


const showMICFullfilled = (payload) => {
    return {
        type: SHOW_MIC_FULLFILLED,
        payload
    }
}

export {showMIC, showMICFullfilled}